<template>
  <div class="p-8 pt-20">
    <h1 class="font-bold uppercase mb-4 text-2xl">Consumables</h1>
    <CreateConsumable />
    <ConsumablesTable />
  </div>
</template>

<script>
import CreateConsumable from '@/components/consumables/CreateConsumable.vue';
import ConsumablesTable from '@/components/consumables/ConsumablesTable.vue';

export default {
  components: {
    CreateConsumable,
    ConsumablesTable
  }
};
</script>
