<template>
  <div class="min-w-260">
    <div class="relative cursor-pointer" @click="copy(date, 'UTC')">
      UTC: {{ UTCDate }}
      <FontAwesomeIcon class="ml-2" icon="copy" />
      <div
        v-if="showCopied === 'UTC'"
        class="anim-object anim-smallcar success bg-green-500 text-white font-bold py-2 px-4 rounded-full"
      >
        Copied {{ date }}
        <FontAwesomeIcon class="ml-2" icon="check" />
      </div>
    </div>
    <p>Local: {{ localDate }}</p>
  </div>
</template>

<script>
export default {
  data () {
    return {
      showCopied: false
    };
  },
  props: {
    date: String
  },
  computed: {
    localDate () {
      const date = this.date ? this.date : undefined;
      return new Date(date).toLocaleString('en-US');
    },
    UTCDate () {
      const date = this.date ? this.date : undefined;
      return new Date(date).toLocaleString('en-US', { timeZone: 'UTC' });
    }
  },
  methods: {
    copy (text, logic) {
      this.showCopied = logic;
      const copyField = document.createElement('textarea');
      document.body.appendChild(copyField);
      copyField.value = text;
      copyField.select();
      document.execCommand('copy');
      document.body.removeChild(copyField);
      this.resetCopied();
    },
    resetCopied () {
      setTimeout(() => {
        this.showCopied = false;
      }, 2000);
    }
  }
};
</script>

<style lang="scss" scoped>
.success {
  position: absolute;
  top: -21px;
  right: -29px;
  opacity: 0;
  animation: opacityOn 3s normal forwards;
}
@keyframes opacityOn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
</style>
