<template>
  <div v-if="loading" class="loading">
    <div class="loader"></div>
  </div>
  <div v-else>
    <h1 class="text-2xl">No {{ type }} found</h1>
  </div>
</template>

<script>
export default {
  props: {
    loading: Boolean,
    type: String
  }
};
</script>

<style lang="scss" scoped></style>
