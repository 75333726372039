var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(!_vm.$store.state.consumablesError.length)?_c('div',[_c('p',{staticClass:"text-right"},[_vm._v(" Total Consumables: "+_vm._s(_vm.$store.state.consumables.length)+" | Updated at: "+_vm._s(new Date().toLocaleString("en-US", { hour: "numeric", minute: "numeric", hour12: true }))+" ")]),_c('vue-good-table',{attrs:{"title":"Consumables Table","columns":_vm.config.columns,"rows":_vm.$store.state.consumables,"pagination-options":{
        enabled: true,
        mode: 'pages',
        perPage: 100,
        position: 'top',
        perPageDropdown: [10, 50, 100, 250, 500, 1000, 10000],
        dropdownAllowAll: false,
        nextLabel: 'next',
        prevLabel: 'prev',
        rowsPerPageLabel: 'Rows per page',
        ofLabel: 'of',
        pageLabel: 'page',
        allLabel: 'All'
      },"search-options":{
        enabled: true,
        initialSortBy: { field: 'consumableStartTime', type: 'desc' }
      },"line-numbers":true},on:{"on-cell-click":_vm.onCellClick},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'deviceId')?_c('a',{attrs:{"href":"#","data-cy":"device-detail-link"}},[_vm._v(_vm._s(props.row.deviceId))]):(props.column.type === 'AWSDateTime')?_c('TwoDates',{attrs:{"date":props.row[props.column.field]}}):(props.column.field === 'edit')?_c('div',[_c('button',{staticClass:"cursor-pointer",attrs:{"data-cy":"delete-device-btn"},on:{"click":function($event){return _vm.showEditModal(props.row)}}},[_vm._v(" EDIT ")])]):_vm._e()]}}],null,false,4108641215)},[_c('div',{staticClass:"emptystate",attrs:{"slot":"emptystate","data-cy":"empty-state"},slot:"emptystate"},[_c('EmptyTable',{attrs:{"loading":_vm.$store.state.loading,"type":"Consumables"}})],1)]),(_vm.editModal)?_c('EditConsumable',{attrs:{"consumable":_vm.selectedConsumable},on:{"close":function($event){return _vm.showEditModal({})}}}):_vm._e()],1):_vm._e(),_c('TableErrors',{attrs:{"errors":_vm.$store.state.consumablesError}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }