<template>
  <div class="text-left">
    <button @click="toggleSessionModal" data-cy="create-session">
      <span>Add a Consumable</span>
    </button>

    <SimpleModal
      class="text-center"
      v-if="toggleModal"
      @close="toggleModal = false"
    >
      <div class="flex flex-wrap mb-14 justify-start">
        <div class="w-1/2">
          <p class="text-xs text-black">Device</p>
          <select
            v-model="deviceId"
            placeholder="12345"
            data-cy="select-session-device"
          >
            <option
              v-for="device in this.$store.state.devices"
              v-bind:key="device.deviceId"
              v-bind:value="device.deviceId"
              >{{ device.deviceType }} - {{ device.deviceId }}</option
            >
          </select>
        </div>
        <div class="w-1/2">
         <p class="text-xs text-black">Consumable Type</p>
          <select
            v-model="consumableType"
            placeholder="CROSS_ACTION"
            data-cy="select-session-device"
          >
            <option
              v-for="consumableType in this.$store.state.consumableTypes"
              v-bind:key="consumableType.name"
              v-bind:value="consumableType.name"
              >{{ consumableType.name }}</option
            >
          </select>
        </div>
        <div class="w-1/2">
          <BaseInput
            class="text-theme-darkest mr-2 "
            label="Drift"
            placeholder="drift"
            v-model.number="drift"
            type="number"
          />
        </div>
        <div class="w-1/2">
          <p class="text-xs text-black">Start Time</p>
          <div
            class="flex justify-start mb-4 p-3 pl-6 w-full"
            style="border-bottom: 1px solid #949494"
          >
            <datetime
              type="datetime"
              v-model="startDate"
              placeholder="Select date"
              value-zone="UTC"
              zone="local"
              :phrases="{ ok: 'Continue', cancel: 'Exit' }"
              :hour-step="1"
              :minute-step="1"
              :week-start="7"
              use12-hour
              auto
            ></datetime>
          </div>
        </div>
      </div>

      <button
        :disabled="loading"
        @click="addConsumable"
        data-cy="submit-create-session"
      >
        <span v-if="loading">{{ loadingMessage }}</span>
        <span v-else>Create Consumable</span>
      </button>
      <p class="error" v-if="formError">{{ formError }}</p>
    </SimpleModal>
  </div>
</template>

<script>
import SimpleModal from '@/components/UI/SimpleModal.vue';
import { Datetime } from 'vue-datetime';

export default {
  data () {
    return {
      toggleModal: false,
      deviceId: this.$store.getters.devices.length
        ? this.$store.getters.devices[0].deviceId
        : '',
      startDate: new Date().toISOString(),
      consumableType: 'CROSS_ACTION',
      drift: 0,
      formError: ''
    };
  },
  components: {
    SimpleModal,
    datetime: Datetime
  },
  created () {
    this.$store.dispatch('updateConsumablesTypes');
  },
  computed: {
    loading () {
      return this.$store.getters.loading;
    },
    loadingMessage () {
      return this.$store.getters.loadingStatusMessage;
    }
  },
  methods: {
    toggleSessionModal () {
      this.toggleModal = !this.toggleModal;
    },

    resetForm () {
      this.startDate = new Date().toISOString();
      this.consumableType = 'CROSS_ACTION';
      this.drift = 0;
      this.deviceId = this.$store.state.devices.length
        ? this.$store.state.devices[0].deviceId
        : '12345';
      this.formError = '';
    },
    setDeviceType () {
      return this.$store.state.devices.length
        ? this.$store.state.devices.filter(device => device.deviceId === this.deviceId)[0].deviceType
        : 'SEAGATE';
    },
    createConsumablesObj () {
      return {
        startDate: this.startDate.split('.')[0] + '+00:00',
        deviceId: this.deviceId,
        deviceType: this.setDeviceType(),
        consumableType: this.consumableType,
        drift: this.drift
      };
    },

    async addConsumable () {
      this.$store
        .dispatch('createConsumableMutation', this.createConsumablesObj())
        .then((res) => {
          if (res.errors) {
            this.formError = res.errors[0].message;
          } else {
            this.$store.dispatch('getConsumablesQuery');
            this.toggleModal = false;
            this.formError = '';
            this.resetForm();
          }
        });
    }
  }
};
</script>
