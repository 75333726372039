<template>
  <div>
    <div v-if="!$store.state.consumablesError.length">
      <p class="text-right">
        Total Consumables: {{ $store.state.consumables.length }} | Updated at:
        {{
          new Date().toLocaleString("en-US", {
            hour: "numeric",
            minute: "numeric",
            hour12: true
          })
        }}
      </p>
      <vue-good-table
        title="Consumables Table"
        :columns="config.columns"
        :rows="$store.state.consumables"
        :pagination-options="{
          enabled: true,
          mode: 'pages',
          perPage: 100,
          position: 'top',
          perPageDropdown: [10, 50, 100, 250, 500, 1000, 10000],
          dropdownAllowAll: false,
          nextLabel: 'next',
          prevLabel: 'prev',
          rowsPerPageLabel: 'Rows per page',
          ofLabel: 'of',
          pageLabel: 'page',
          allLabel: 'All'
        }"
        :search-options="{
          enabled: true,
          initialSortBy: { field: 'consumableStartTime', type: 'desc' }
        }"
        :line-numbers="true"
        @on-cell-click="onCellClick"
      >
        <div slot="emptystate" class="emptystate" data-cy="empty-state">
          <EmptyTable :loading="$store.state.loading" type="Consumables" />
        </div>
        <template slot="table-row" slot-scope="props">
          <a
            href="#"
            v-if="props.column.field === 'deviceId'"
            data-cy="device-detail-link"
            >{{ props.row.deviceId }}</a
          >
          <TwoDates
            v-else-if="props.column.type === 'AWSDateTime'"
            :date="props.row[props.column.field]"
          />
           <div v-else-if="props.column.field === 'edit'">
            <button
            @click="showEditModal(props.row)"
              class="cursor-pointer"
              data-cy="delete-device-btn"
            >
              EDIT
            </button>
          </div>
        </template>
      </vue-good-table>
      <EditConsumable v-if="editModal" :consumable="selectedConsumable" @close="showEditModal({})" />
    </div>
    <TableErrors v-bind:errors="$store.state.consumablesError" />
  </div>
</template>

<script>
import TwoDates from '@/components/UI/TwoDates';
import brandConfig from '@/util/brandConfigs';
import currentBrand from '@/util/getCurrentBrand';
import TableErrors from '@/components/UI/TableErrors.vue';
import EditConsumable from '@/components/consumables/EditConsumable.vue';
import EmptyTable from '@/components/UI/EmptyTable.vue';

export default {
  data () {
    return {
      config: brandConfig[currentBrand()].consumables,
      pageTokens: [],
      currentPage: 1,
      FORWARD: 'forward',
      BACKWARD: 'backward',
      limit: 100,
      options: [10, 50, 100, 200, 500],
      editModal: false,
      selectedConsumable: {}
    };
  },
  components: {
    TableErrors,
    TwoDates,
    EmptyTable,
    EditConsumable
  },
  methods: {
    showEditModal (consumable) {
      this.editModal = !this.editModal;
      this.selectedConsumable = consumable;
    },
    onCellClick: function (params) {
      if (params.column.field === 'deviceId' && this.ownsDevice(params.row.deviceId)) {
        const { row: { deviceId } } = params;

        this.$store.dispatch('getSelectedDeviceQuery', {
          deviceId: params.row.deviceId
        })
          .then((res) => {
            if (res.errors) {
              alert(res.errors[0].message);
            } else {
              this.$router.push({
                name: 'deviceDetail',
                params: { deviceId },
                query: { prevPage: '/devices' }
              });
            }
          });
      }
    },
    ownsDevice (id) {
      const device = this.$store.state.devices.find(device => device.deviceId === id);
      return device ? true : alert('You no longer own this device');
    }
  }
};
</script>
