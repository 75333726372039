<template>
  <div v-if="errors.length">
    <p class="mt-4 mb-4">There was an error retrieving the data:</p>
    <ol>
      <li class="mb-4" v-for="(error, idx) in errors" v-bind:key="idx">
        <vue-json-pretty
          :data="error"
          :deep="1"
          showLength
          showLine
          showDoubleQuotes
          highlightMouseoverNode
        />
      </li>
    </ol>
  </div>
</template>

<script>
export default {
  props: {
    errors: {
      type: Array
    }
  }
};
</script>
