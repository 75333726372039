<template>
  <div class="inline-block">
    <SimpleModal @close="closeModal">
      <div>
    <h2 class="text-center font-bold uppercase mb-4 text-2xl">
      Device Id: #{{ deviceId }}
    </h2>
    <div class="flex flex-wrap mb-14 justify-start">
      <div class="w-1/3">
        <p class="text-xs text-black">Consumable Type</p>
       <select
          v-model="consumableType"
          placeholder="CROSS_ACTION"
        >
          <option
            v-for="consumableType in this.$store.state.consumableTypes"
            v-bind:key="consumableType.name"
            v-bind:value="consumableType.name"
            >{{ consumableType.name }}</option
          >
        </select>
      </div>

      <div class="w-1/3">
        <p class="text-xs text-black">Consumable State</p>
       <select
          v-model="state"
          placeholder="USED"
        >
          <option
            v-for="state in this.$store.state.consumableStates"
            v-bind:key="state.name"
            v-bind:value="state.name"
            >{{ state.name }}</option
          >
        </select>
      </div>

      <div class="w-1/3">
        <BaseInput
          class="text-theme-darkest mr-4"
          label="Drift"
          placeholder="drift"
          v-model.number="drift"
          type="number"
        />
      </div>
    </div>
    <div class="text-center">
      <button @click="updateConsumable" :disabled="loading">
        <span v-if="loading">{{ loadingMessage }}</span>
        <span v-else>Update Consumable</span>
      </button>
    </div>
    <p class="error" v-if="formError">{{ formError }}</p>
  </div>
    </SimpleModal>
  </div>
</template>

<script>
import SimpleModal from '@/components/UI/SimpleModal.vue';

export default {
  data: function () {
    return {
      formError: null,
      deviceId: null,
      consumableType: null,
      state: null,
      drift: null
    };
  },
  components: {
    SimpleModal
  },
  computed: {
    loading () {
      return this.$store.getters.loading;
    },
    loadingMessage () {
      return this.$store.getters.loadingStatusMessage;
    }
  },
  created () {
    const { consumable } = this.$attrs;
    this.$store.dispatch('updateConsumablesStates');
    this.deviceId = consumable.deviceId;
    this.consumableId = consumable.consumableId;
    this.consumableType = consumable.consumableType;
    this.state = consumable.state;
    this.drift = consumable.drift;
  },
  methods: {
    // at close, emits the event close passing false to showDeleteModal
    closeModal () {
      this.$emit('close', false);
    },
    resetForm () {
      this.$store.dispatch('getConsumablesQuery');
      this.$emit('close');
    },
    async updateConsumable () {
      this.$store
        .dispatch('updateConsumableMutation', {
          consumableId: this.consumableId,
          consumableType: this.consumableType,
          state: this.state,
          drift: this.drift
        })
        .then((res) => {
          if (res.data) {
            this.formError = '';
            this.resetForm();
          } else {
            this.formError = res.errors[0].message;
          }
        })
        .catch(() => {
          this.formError = 'Sorry - There was an error. Please try again.';
        });
    }
  }
};
</script>
<style scoped>
.modal[data-v-f08989e0] {
  top: 25%;
}
</style>
